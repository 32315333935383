import React, { Component } from 'react';
import { isSameDay } from 'react-dates';
import moment from 'moment';

import { getTotalUnitsProduced } from '../apiClients/logistics';
import { getDateString } from '../utils';

import InfoBox from '../components/InfoBox';
import SingleValueDisplay from '../components/SingleValueDisplay';
import FilterPicker from '../components/FilterPicker';

import Poller from './Poller';


class TotalUnitsProducedController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      pastValue: null,
      initialFetch: true,
      startDate: moment(),
      endDate: moment(),
      error: false,
    }
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchData(startDate, endDate);
  }

  fetchData = (startDate, endDate) => {
    const fetchExtra = isSameDay(startDate, endDate);

    getTotalUnitsProduced(startDate, endDate)
      .then((data) => {
        const stateData = {
          value: data.product_count,
          initialFetch: false,
          error: false,
        }
        if (!fetchExtra) {
          stateData.pastValue = null;
        }
        this.setState(stateData);
      })
      .catch((error) => {
        this.setState({
          initialFetch: false,
          error: true,
        });
      });


    if (isSameDay(startDate, endDate)) {

      const theDayBefore = startDate.clone().subtract(1, 'days');
      // We'll consider this secondary "nice to have" value, and not attach
      // any loading states to it, for now.
      getTotalUnitsProduced(theDayBefore, theDayBefore)
        .then((data) => {
          this.setState({pastValue: data.product_count})
        });
    }

  }

  handleFilterChange = ({ startDate, endDate}) => {
    this.setState({ startDate, endDate });
    this.fetchData(startDate, endDate);
  }

  render() {
    const { title } = this.props;
    const { error, initialFetch, startDate, endDate, value, pastValue } = this.state;

    const filterPicker = (
      <FilterPicker
        startDate={startDate}
        endDate={endDate}
        handleDateChange={this.handleFilterChange}
      />
    );

    const subTitle = getDateString(startDate, endDate);
    const pastDate = isSameDay(startDate, endDate) ?
      startDate.clone().subtract(1, "days").format("MMM Do") : null;

    return (
      <InfoBox
        title={title}
        error={error}
        configContent={filterPicker}
        subTitle={subTitle}
      >
        <Poller
          intervalSeconds={30}
          func={this.fetchData}
          parameters={[startDate, endDate]}
        />
        <SingleValueDisplay
          value={value}
          pastValue={pastValue}
          loading={initialFetch}
          pastDate={pastDate}
        />
      </InfoBox>
    );
  }
}

export default TotalUnitsProducedController;
