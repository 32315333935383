import React from 'react';


const NoDataFound = (props) => {
  return (
    <div className="mt-5 text-secondary">
      <small>No data found</small>
    </div>
  );
};

export default NoDataFound;
