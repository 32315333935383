import { isLoggedIn } from "axios-jwt";
import React from 'react';
import { Redirect } from "react-router-dom";
import { Col, Container, Row } from 'reactstrap';

import { ReactComponent as Logo } from '../vellamo-logo.svg';
import { arrayToRows } from '../utils';

import LogoutHandler from './LogoutHandler';


const Dashboard = (props) => {
  const { columns, name } = props;
  const childRows = arrayToRows(props.children, columns);

  if (!isLoggedIn()) {
    return <Redirect to="/login" />;
  }

  return (
    <Container>
      <header>
        <Row>
          <Col sm="6">
            <Logo style={{maxWidth: "14rem"}} />
          </Col>
          <Col sm="6" className="text-right pt-2">
            <img
              src="upm_rafmore_logo.png"
              alt="Logo of Rafmore by UPM Raflatec"
              style={{maxWidth: "16rem"}}
            />
          </Col>
        </Row>
        <h3
          className="text-uppercase text-primary mt-2 font-weight-normal"
        >
          {name} Dashboard
        </h3>
      </header>
      <Row>
        {/* TODO: Fix this column looping not having any difference
                  to the actual amount of columns present.
        */}
        {childRows.map((row, i) =>
          (row.map((child, j) =>
            <Col xs="12" sm="12" md="6" lg="4" key={j} className="p-2">
              {child}
            </Col>
          ))
        )}
      </Row>
      <Row className="p-0 m-0">
        <Col className="text-right p-0 m-0">
          <span><LogoutHandler /></span>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
