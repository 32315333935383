import React, { Component } from 'react';

import { getInventoryLocations } from '../apiClients/logistics';

import InfoBox from '../components/InfoBox';
import MapDisplay from '../components/MapDisplay';

import Poller from './Poller';


class InventoryLocationsController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      initialFetch: true,
      error: false,
    }
  }

  normalizeCoordinates = (locations) => {
    // API returns the data in arrays of [lng, lat], but the libraries
    // we use for map things use different formats for Points. Try to
    // please everyone with one franken-Point object.
    return locations.map(longlat => {
      if (longlat.coordinates) {
        const [ lng, lat ] = longlat.coordinates.coordinates;
        longlat.coords = {
          latitude: lat,
          longitude: lng,
          lat, lng,
        };
      }
      return longlat;
    });
  }

  fetchData = () => {
    getInventoryLocations()
      .then((data) => {
        this.setState({
          values: this.normalizeCoordinates(data),
          initialFetch: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          initialFetch: false,
          error: true,
        });
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { title } = this.props;
    const { error, values, initialFetch } = this.state;
    return (
      <InfoBox title={title} allowUnderDraw={true} error={error}>
        <Poller
          func={this.fetchData}
        />
        <MapDisplay
          locations={values}
          loading={initialFetch}
        />
      </InfoBox>
    );
  }
}

export default InventoryLocationsController;
