import { Component } from 'react';
import isEqual from 'react-fast-compare';


// Go for a relatively safe value, when nothing is specifcied.
const DEFAULT_TIME = process.env.REACT_APP_DEFAULT_POLL_TIME || 60;


class Poller extends Component {
  componentDidMount() {
    this.addPoller()
  }

  componentWillUnmount() {
    this.clearPoller()
  }

  componentDidUpdate(prevProps) {
    const lastParams = prevProps.parameters;
    const curParams = this.props.parameters;

    // Reboot the poller when fetching parameter changes (eg. new filter)
    if (!isEqual(lastParams, curParams)) {
      this.clearPoller()
      this.addPoller()
    }
  }

  addPoller = () => {
    const { func, parameters, intervalSeconds } = this.props;

    const seconds = parseInt(intervalSeconds || DEFAULT_TIME);
    if (isNaN(seconds)) {
      console.warn(`Poller received '${seconds}', which is not a number. Aborting polling.`)
      return
    }

    const _params = parameters || [];

    this.poller = window.setInterval(
      () => func(..._params),
      seconds * 1000
    )

    console.log(`Polling every ${seconds} seconds.`)
  }

  clearPoller = () => {
    window.clearInterval(this.poller);
    this.poller = null;
  }

  render() {
    return null;
  }
}


export default Poller;
