import React from 'react';

import { Card, CardBody, CardTitle } from 'reactstrap';

import MiniAlert from './MiniAlert';
import ConfigController from '../containers/ConfigController';


const InfoBox = (props) => {
  const {
    allowUnderDraw,
    children,
    configContent,
    error,
    title,
    subTitle,
  } = props;

  // NOTE: This is a simple hack for getting the map (or any other) widget
  // *under* the header, when we so desire. This oughta be made more dynamic,
  // responsive, etc.
  const mapHax = {zIndex: 3000, width: "237px"};

  const style = allowUnderDraw ? mapHax : {};
  const overflow = allowUnderDraw ? "" : "overflow-auto";

  // Tooltips & popovers need a unique ID to function
  const boxId = title.replace(/ /g, '').toLowerCase();

  return (
    <Card className="bg-white border" style={{height: "300px"}}>
      <CardTitle className="mt-3 pl-4 bg-white" style={style}>
        {title}
        {error && <MiniAlert message={"Error loading data from the backend"} id={boxId} />}
        <span
          className="position-absolute"
          style={{right: "1rem", top: "1rem"}}
        >
          <small className="text-secondary">{subTitle}</small>
          <ConfigController id={boxId}>
            {configContent}
          </ConfigController>
        </span>
      </CardTitle>
      <CardBody className={`text-center align-middle p-2 ${overflow}`}>
        {children}
      </CardBody>
    </Card>
  );
}

export default InfoBox;
