import React, { useState } from 'react';

import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faTimes } from '@fortawesome/free-solid-svg-icons'


const ConfigController = (props) => {
  const { id, children } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  if (!children) {
    return null;
  }

  const configId = `config-${id}`;

  return (
    <>
      <Button
        className="bg-white btn-circle border-0 ml-1"
        onClick={toggle}
        id={configId}
      >
        <FontAwesomeIcon
          className="text-secondary"
          icon={isOpen ? faTimes : faCog}
        />
      </Button>
      <Popover
        placement="right-end"
        isOpen={isOpen}
        target={configId}
        toggle={toggle}
        fade={false}
        trigger="legacy"
      >
        <PopoverHeader></PopoverHeader>
        <PopoverBody>
          {children}
        </PopoverBody>
      </Popover>
    </>
  );
}


export default ConfigController;
