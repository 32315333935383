import axios from 'axios';
import { useAuthTokenInterceptor, setAuthTokens } from "axios-jwt";


// Magic Add Logistics API
// Define any API functions here.

const BASE_URL = process.env.REACT_APP_LOGISTICS_API_URL;
if (!BASE_URL) throw new Error("BASE_URL is not defined");


const logisticsAPI = axios.create({
  baseURL: BASE_URL,
})


const requestRefresh = (refresh) => {
  console.log("TRYING A JWT REFRESH, IN VAIN");
  return refresh
  // Refresh not yet implemented on backend, leave a reference impelmentation here
  // for when it is available.

  // return new Promise((resolve, reject) => {
  //   // notice that this is the global axios instance.  <-- important
  //   logisticsAPI.post('/token/refresh', { refresh })
  //   .then(response => {
  //     resolve(response.data.accessToken);
  //   }, reject);
  // });
};

useAuthTokenInterceptor(logisticsAPI, { headerPrefix: "JWT ", requestRefresh });


export const authResponseToAuthTokens = (res) => ({
  accessToken: res.data.token,
  // Not yet enabled in backend, but required for axios-jwt to work:
  refreshToken: res.data.token  // TODO: Change this
});

export const postLogin = (data) => {
  return logisticsAPI.post("/token/obtain/", data)
    .then((response) => {
      setAuthTokens(authResponseToAuthTokens(response));
      return response.data;
    })
};


const _handleResponse = (response) => {
  const { data } = response;

  // console.log("GET", response.request.responseURL);
  // console.log("DATA:", data);
  if (!data) {
    // TODO: Do something fancier here.
    console.error(`No data received for ${response}`);
    return
  }

  return data;
};

const API_DATE_FMT = "YYYY-MM-DD";


const _handleParams = (after, before) => {
  let exclBefore = before ? before.clone().add(1, "days") : null;

  return {
    production_time_after: after && after.format(API_DATE_FMT),
    production_time_before: exclBefore && exclBefore.format(API_DATE_FMT),
  }
}

export const getTotalUnitsProduced = (after, before) => {
  const params = _handleParams(after, before);

  return logisticsAPI.get("/total_production/", {params})
    .then(_handleResponse)
};


export const getUnitsProduced = (after, before) => {
  const params = _handleParams(after, before);

  return logisticsAPI.get("/total_production/products/", {params})
    .then(_handleResponse)
};


export const getProductionRates = (after, before) => {
  const params = _handleParams(after, before);

  return logisticsAPI.get("/production_rates/", {params})
    .then(_handleResponse)
};


export const getInventoryStatuses = (after, before) => {
  const params = _handleParams(after, before);

  return logisticsAPI.get("/inventory_statuses/", {params})
    .then(_handleResponse)
};


export const getInventoryLocations = () => {
  return logisticsAPI.get("/inventory_locations/")
    .then(_handleResponse)
};


export const getInTransitInventory = () => {
  return logisticsAPI.get("/in_transit_inventory/")
    .then(_handleResponse)
};
