import React, { Component } from "react";
import { Button, Card, CardBody, Col, Form, Input, Row, Spinner } from 'reactstrap';
import { Redirect } from "react-router-dom";

import { ReactComponent as Logo } from '../vellamo-logo.svg';
import { postLogin } from '../apiClients/logistics';


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      success: false,
      processing: false,
      username: "",
      password: ""
    };
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;

    this.setState({error: null, processing: true});

    postLogin({ username, password })
      .then((data) => {
        // TODO: Make sure 200 OK is actually always a success?
        this.setState({success: true, processing: false});
        return data;

      }).catch((error) => {
        this.setState({error: error, processing: false});
        throw error;
      });
  }

  render() {
    const { error, username, password, processing, success } = this.state;

    if (success) {
      return <Redirect to="/dashboard" />;
    }

    return (
      <div className="container" style={{paddingTop: "20%"}}>
        <Row>
          <Col>
            <Card className="px-4 py-5 mx-auto" style={{maxWidth: "320px"}}>
              <CardBody>
                <div className="mb-5 px-2">
                  <Logo />
                </div>
                <Form onSubmit={this.handleSubmit}>
                  <Input
                    className="mb-2"
                    disabled={processing}
                    name="username"
                    onChange={this.handleChange}
                    placeholder="Username"
                    type="text"
                    value={username}
                  />
                  <Input
                    className="mb-2"
                    disabled={processing}
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Password"
                    type="password"
                    value={password}
                  />
                  <Button
                    className="btn-block"
                    color="primary"
                    disabled={processing}
                    type="submit"
                  >
                    {processing ? <Spinner size="sm" color="white" /> : "Login"}
                  </Button>
                </Form>
                {error &&
                  <p
                    className="text-danger p-0 mt-3 position-fixed"
                    style={{left: "50%", transform: "translate(-50%)"}}
                  >
                  Login failed
                  </p>
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
};


export default Login;
