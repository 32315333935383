import React from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


const MiniAlert = (props) => {
  const { id, message } = props;

  return (
    <span id={id}>
      <FontAwesomeIcon
        className="ml-2 text-warning"
        icon={faExclamationTriangle}
      />
      <UncontrolledTooltip
        placement="top"
        target={id}
      >
        {message}
      </UncontrolledTooltip>
    </span>
  );
}

export default MiniAlert;
