import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'

import NoDataFound from './NoDataFound';
import Loading from './Loading';


const calcTrendPercent = (value, pastValue) => {
  if (!value || !pastValue) {
    return 0;
  }
  return (value - pastValue) / pastValue * 100;
}

const SingleValueDisplay = (props) => {
  const { pastDate, value, pastValue, loading } = props;

  if (loading) {
    return <Loading />;
  }

  const validValue = typeof value === 'string' || typeof value === 'number';
  if (!validValue || value === '') {  // SIC: Allow number 0
    return <NoDataFound />;
  }

  const trendPercent = calcTrendPercent(value, pastValue);

  const trendIcon = trendPercent > 0 ? faCaretUp : faCaretDown;
  const trendColor = trendPercent > 0 ? "text-success" : "text-danger";

  return (
    <div className="mt-5">
      <div className="text-secondary">Total production</div>
      <div>
        <span style={{fontSize: "3.3rem", lineHeight: "0.9"}}>{value}</span>
      </div>
      { trendPercent !== 0 &&
        <div className="text-secondary">
          <span className={trendColor}>
            <FontAwesomeIcon icon={trendIcon} />
          </span>
          &nbsp;
          {Math.abs(trendPercent.toFixed(0))}% <small>since {pastDate}</small>
        </div>
      }
    </div>
  );
}

export default SingleValueDisplay;
