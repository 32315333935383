import React, { Component } from 'react';
import moment from 'moment';

import { getProductionRates } from '../apiClients/logistics';
import { getDateString } from '../utils';

import InfoBox from '../components/InfoBox';
import LineGraphDisplay from '../components/LineGraphDisplay';
import FilterPicker from '../components/FilterPicker';

import Poller from './Poller';


class ProductionRateController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      values: [],
      initialFetch: true,
      startDate: null,
      endDate: null,
      error: false,
    }
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchValues(startDate, endDate);
  }

  getLabels = (rates) => {
    return rates.map((rate) => moment(rate.date).format("D.M."));
  }

  getValues = (rates) => {
    return rates.map((rate) => rate.count);
  }

  fetchValues = (startDate, endDate) => {
    getProductionRates(startDate, endDate)
      .then((data) => {
        this.setState({
          labels: this.getLabels(data),
          values: this.getValues(data),
          initialFetch: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          initialFetch: false,
          error: true,
        });
      });
  }

  handleFilterChange = ({ startDate, endDate}) => {
    this.setState({ startDate, endDate });
    this.fetchValues(startDate, endDate);
  }

  render() {
    const { title } = this.props;
    const { error, initialFetch, startDate, endDate, labels, values } = this.state;

    const filterPicker = (
      <FilterPicker
        startDate={startDate}
        endDate={endDate}
        handleDateChange={this.handleFilterChange}
      />
    );

    const subTitle = getDateString(startDate, endDate)

    return (
      <InfoBox
        title={title}
        error={error}
        configContent={filterPicker}
        subTitle={subTitle}
      >
        <Poller
          intervalSeconds={30}
          func={this.fetchValues}
          parameters={[startDate, endDate]}
        />
        <LineGraphDisplay
          labels={labels}
          values={values}
          loading={initialFetch}
        />
      </InfoBox>
    );
  }
}

export default ProductionRateController;
