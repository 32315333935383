import React, { Component } from 'react';
import { Switch, Redirect, Route } from "react-router-dom";

import { isLoggedIn } from "axios-jwt";

import Dashboard from './containers/Dashboard';
import Login from './containers/Login';

import InventoryController from './containers/InventoryController';
import InventoryLocationsController from './containers/InventoryLocationsController';
import ProductionRateController from './containers/ProductionRateController';
import TotalUnitsProducedController from './containers/TotalUnitsProducedController';
import TransitController from './containers/TransitController';
import UnitsProducedController from './containers/UnitsProducedController';


class App extends Component {
  render() {
    return (
      <div className="my-5">
        <Switch>
          <Route exact path="/">
            {isLoggedIn() ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
          </Route>
          <Route exact path={"/login"} component={Login}/>
          <Route exact path={"/dashboard"}>
            <Dashboard
              name="Operations"
              columns={3}
            >
              <TotalUnitsProducedController title="Total units produced" />
              <UnitsProducedController title="Bottles produced" />
              <ProductionRateController title="Production Rate" />
              <InventoryLocationsController title="Warehouse status - Global" />
              <InventoryController title="Inventory" />
              <TransitController title="In Transit" />
            </Dashboard>
          </Route>
        </Switch>
        <footer className="fixed-bottom text-center bg-white">
          <small
            className="text-secondary"
            style={{fontSize: "0.65rem"}}
          >
            Powered by:
          </small>
          <img
            src="./magic_add_logo.png"
            alt="Logo of Magic Add"
            className="py-3 px-2"
            style={{maxWidth: "130px"}}
          />
        </footer>
      </div>
    );
  }
}

export default App;
