import moment from 'moment';
import { isSameDay } from 'react-dates';


export const getDateString = (start, end) => {
  const today = moment();
  const yesterday = moment().subtract(1, "days");

  const format = "MMM Do"
  const _formatDate = (day) => {
    return day ? day.format(format) : "";
  }

  if (!start && !end) {
    return "all time";
  }

  if (isSameDay(start, end)) {
    if (isSameDay(start, today)) {
      return "today";
    }
    if (isSameDay(start, yesterday)) {
      return "yesterday";
    }

    return _formatDate(start);
  }

  return `${_formatDate(start)} — ${_formatDate(end)}`;
}


export const randomFloat = (min, max) => {
  return Math.random() * (max - min) + min;
}


export const arrayToRows = (array, rowLength) => {
  const rows = [];
  for(var i=0; i<array.length; i+=rowLength) {
    rows.push(array.slice(i, i+rowLength));
  }
  return rows;
}
