import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Redirect } from "react-router-dom";
import { clearAuthTokens } from "axios-jwt";


const LogoutHandler = (props) => {
  const [doRedirect, setDoRedirect] = useState(false);

  if (doRedirect) {
    return <Redirect to="/login" />;
  }

  const logout = () => {
    clearAuthTokens();
    setDoRedirect(true);
  }

  return (
    <Button
      color="white"
      className="p-0 m-0"
      onClick={logout}
    >
      <small>Logout</small>
    </Button>
  )

}

export default LogoutHandler;
