import React, { Component } from 'react';

import { getInventoryStatuses } from '../apiClients/logistics';

import InfoBox from '../components/InfoBox';
import TableDisplay from '../components/TableDisplay';

import Poller from './Poller';


class InventoryController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      initialFetch: true,
      error: false,
    }
  }

  getTableValues = (inventory) => {
    return inventory.map((item) => {
      const line = [item.warehouse, item.total_count];

      const products = item.product_counts.map((product) => {
        const id = product.product_id;
        const name = id ?
          product.product || `Product ${id}`:
          "Unknown products";  // SIC
        // Products without ID can be multiple data points mashed into one.

        return [name, product.box_count];
      });

      return {line: line, children: products};
    });
  }

  fetchData = () => {
    getInventoryStatuses()
      .then((data) => {
        this.setState({
          values: this.getTableValues(data),
          initialFetch: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          initialFetch: false,
          error: true,
        });
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { title } = this.props;
    const { error, initialFetch, values } = this.state;
    return (
      <InfoBox title={title} error={error}>
        <Poller
          func={this.fetchData}
        />
        <TableDisplay
          headers={["Warehouse", "Boxes"]}
          items={values}
          loading={initialFetch}
        />
      </InfoBox>
    );
  }
}

export default InventoryController;
