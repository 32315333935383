import classNames from 'classnames';
import React, { useState } from 'react';
import { Table, Collapse } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons'

import NoDataFound from './NoDataFound';
import Loading from './Loading';


const TableItem = (props) => {
  // Instead of a simple "Row" we have an "Item", because each main row
  // may have collapsible children.
  const { item } = props;

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const itemClasses = (i) => classNames(
    // Twiddling to make the paddings & borders play nice when collapsing.
    {"border-0 p-0": !isOpen},

    // Indentation for first column of children.
    {"pl-4": i === 0},
  );

  const hasChildren = item.children && item.children.length;

  const caret = isOpen ?
    <FontAwesomeIcon className="text-secondary" icon={faCaretDown} /> :
    <FontAwesomeIcon className="text-secondary" icon={faCaretRight} />;

  const parentItemStyle = hasChildren ? {cursor: "pointer"} : {};

  const showCaret = (i) => {
    return hasChildren && i === 0;
  }

  return (<>
    <tr onClick={hasChildren && toggle} style={parentItemStyle}>
      {item.line.map((value, i) => (
        <td key={i}>{showCaret(i) && caret} &nbsp; {value}</td>
      ))}
    </tr>
    {hasChildren && item.children.map((child, i) =>
      <tr key={i}>
        {child.map((value, j) => (
          <td key={j} className={itemClasses(j)}>
            <Collapse isOpen={isOpen}>
              &nbsp; {value}
            </Collapse>
          </td>
        ))}
      </tr>
    )}
  </>)
};


const TableDisplay = (props) => {
  const { items, headers, loading } = props;

  if (loading) {
    return <Loading />;
  }

  const hasItems = items && items.length;
  if (!hasItems) {
    return <NoDataFound />;
  }

  // Minimize jumping around when opening/closing children
  const headerStyle = (i) => ({width: i === 0 && "80%"});

  return (
    <div className="text-left" style={{fontSize: "0.8rem", height:"100%"}}>
      <Table size="sm">
        <thead className="text-secondary">
          <tr>
            {headers.map((header, i) => (
              <th className="border-0" key={i} style={headerStyle(i)}>
                <small>
                  &nbsp; {header}
                </small>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item, i) =>
            <TableItem
              key={i}
              item={item}
            />
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default TableDisplay;
