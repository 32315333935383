import moment from 'moment';

import React from 'react';
import { DayPickerRangeController, isInclusivelyBeforeDay } from 'react-dates';
import { Container, Col, Row, Button } from 'reactstrap';


const getPresets = () => {
  const today = moment();
  const yesterday = moment().subtract(1, 'day');
  return [{
    text: 'Today',
    start: today,
    end: today,
  },
  {
    text: 'Yesterday',
    start: yesterday,
    end: yesterday,
  },
  {
    text: 'Past Week',
    start: moment().subtract(1, 'week'),
    end: today,
  },
  {
    text: 'Past Month',
    start: moment().subtract(1, 'month'),
    end: today,
  },
  {
    text: 'All Time',
    start: null,
    end: null,
  }];
}


class FilterPicker extends React.Component {
  constructor(props) {
    super(props);

    this.START_ID = "startDate" // `startDate-${props.id}`;
    this.END_ID = "endDate" // `endDate-${props.id}`;
    this.presets = getPresets();

    this.state = {
      focusedInput: this.START_ID,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // TODO: Fix the range selection to allow setting the startDate "forward"
  }

  onFocusChange = (focusedInput) => {
    this.setState({ focusedInput });
  }

  handleDateChange = ({startDate, endDate}) => {
    // Tiny usability hack; show the data for "one day" when the
    // endDate is not yet selected. Without this, the API would always
    // return startDate -> Today.
    const end = endDate ? endDate : startDate;
    this.props.handleDateChange({ startDate, endDate: end })
  }

  renderExtra = () => {
    return (
      <Container>
        <Row>
        {this.presets.map(({ text, start, end }) =>
          <Col xs="6" className="p-0">
            <Button
              key={text}
              size="sm"
              type="button"
              className="btn-block text-secondary"
              color="white"
              onClick={() => this.handleDateChange({ startDate: start, endDate: end })}
            >
              {text}
            </Button>
          </Col>
        )}
        </Row>
      </Container>
    );
  }

  render() {
    const { focusedInput } = this.state;

    const { startDate, endDate, handleDateChange } = this.props;

    return (
      <DayPickerRangeController
        disabled={true}
        keepOpenOnDateSelect={true}

        renderCalendarInfo={this.renderExtra}
        startDate={startDate}
        endDate={endDate}
        onDatesChange={this.handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={this.onFocusChange}
        initialVisibleMonth={() => moment()}

        daySize={34}
        firstDayOfWeek={1}
        hideKeyboardShortcutsPanel={true}
        minimumNights={0}
        numberOfMonths={1}
        calendarInfoPosition="bottom"
        isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
        noBorder={true}
      />
    );
  }
}

export default FilterPicker;
