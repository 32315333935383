import React from 'react';
import { Spinner } from 'reactstrap';


const Loading = (props) => {
  const size = "5rem";
  return (
    <Spinner
      style={{width: size, height: size, borderWidth: "2px"}}
      className="mt-5"
      color="primary"
    />
  );
};

export default Loading;
