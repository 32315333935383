import React, { Component } from 'react';

import { getUnitsProduced } from '../apiClients/logistics';
import { getDateString } from '../utils';

import InfoBox from '../components/InfoBox';
import TableDisplay from '../components/TableDisplay';
import FilterPicker from '../components/FilterPicker';

import Poller from './Poller';


class UnitsProducedController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      initialFetch: true,
      startDate: null,
      endDate: null,
      error: false,
    }
  }

  componentDidMount() {
    const { startDate, endDate } = this.state;
    this.fetchData(startDate, endDate);
  }

  getTableValues = (products) => {
    return products.map((product) => {
      const id = product.product_id;
      const name = id ?
        product.product || `Product ${id}`:
        "Unknown products";  // SIC
      // Products without ID can be multiple data points mashed into one.

      const line = [name, product.product_count];

      return {line};
    });
  }

  fetchData = (startDate, endDate) => {
    getUnitsProduced(startDate, endDate)
      .then((data) => {
        this.setState({
          products: this.getTableValues(data),
          initialFetch: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          initialFetch: false,
          error: true,
        });
      });
  }

  handleFilterChange = ({ startDate, endDate}) => {
    this.setState({ startDate, endDate });
    this.fetchData(startDate, endDate);
  }

  render() {
    const { title } = this.props;
    const { error, initialFetch, startDate, endDate, products } = this.state;

    const filterPicker = (
      <FilterPicker
        startDate={startDate}
        endDate={endDate}
        handleDateChange={this.handleFilterChange}
      />
    );

    const subTitle = getDateString(startDate, endDate);

    return (
      <InfoBox
        title={title}
        error={error}
        configContent={filterPicker}
        subTitle={subTitle}
      >
        <Poller
          func={this.fetchData}
          parameters={[startDate, endDate]}
        />
        <TableDisplay
          headers={["Product", "Bottles"]}
          items={products}
          loading={initialFetch}
        />
      </InfoBox>
    );
  }
}

export default UnitsProducedController;
