import { getCenterOfBounds } from 'geolib';
import React from 'react';
import { Map, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';

import NoDataFound from './NoDataFound';
import Loading from './Loading';
import { randomFloat } from '../utils';


const getRandomLocInFinland = () => [randomFloat(61, 64), randomFloat(23, 28)];

const objToLatLng = (coords) => [coords.latitude, coords.longitude];


const MapMarker = (props) => {
  const { coordinates, displayData } = props;

  // Randomize the position, in case we have multiple warehouses without
  // coordinates. This is not really useful data anymore, but it's nice
  // for testing purposes, because the warehouse usually still has other
  // data present.
  const defaultPosition = getRandomLocInFinland();
  const position = coordinates || defaultPosition;

  return (
    <Marker position={position}>
      <Popup>
        {displayData}
      </Popup>
    </Marker>
  )
}


const MapDisplay = (props) => {
  const { locations, loading } = props;

  if (loading) {
    return <Loading />;
  }

  const hasLocations = Array.isArray(locations) && locations.length;
  if (!hasLocations) {
    return <NoDataFound />;
  }


  // TODO: Decouple the display data from the locations endpoint, for proper
  // general-purpose map widget.

  const defaultCenter = [63.055, 22.781];
  const defaultZoom = 1;  // TODO: Calculate according to bounding box size

  const allCoords = locations.map(
    (loc) => loc.coords || getRandomLocInFinland()
  );

  const center = (allCoords && allCoords.length)
    ? objToLatLng(getCenterOfBounds(allCoords))
    : defaultCenter;

  return (
    <Map
      center={center}
      zoom={defaultZoom}
      style={{marginTop: "-50px", height:280}}
      zoomControl={false}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControl position="bottomright" />
      {locations.map((loc, i) =>
        <MapMarker
          key={i}
          coordinates={loc.coords}
          displayData={`Inventory: ${loc.inventory_count} units`}
        />
      )}
    </Map>
  );
}

export default MapDisplay;
