import React, { Component } from 'react';

import { getInTransitInventory } from '../apiClients/logistics';

import InfoBox from '../components/InfoBox';
import TableDisplay from '../components/TableDisplay';

import Poller from './Poller';


class TransitController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      initialFetch: true,
      error: false,
    }
  }

  getTableValues = (inventory) => {
    return inventory.map((item) => {
      const line = [item.product, item.box_count];

      return {line};
    });
  }

  fetchData = () => {
    getInTransitInventory()
      .then((data) => {
        this.setState({
          values: this.getTableValues(data),
          initialFetch: false,
          error: false,
        });
      })
      .catch((error) => {
        this.setState({
          initialFetch: false,
          error: true,
        });
      });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { title } = this.props;
    const { error, initialFetch, values } = this.state;

    return (
      <InfoBox title={title} error={error}>
        <Poller
          func={this.fetchData}
        />
        <TableDisplay
          headers={["Product", "Boxes"]}
          items={values}
          loading={initialFetch}
        />
      </InfoBox>
    );
  }
}

export default TransitController;
