import React from 'react';

import { Line } from 'react-chartjs-2';
import Loading from '../components/Loading';


const BLUE = "rgba(57, 176, 233, 255)";
const BG1 = "rgba(181, 227, 249, 255)";
const BG2 = "rgba(181, 227, 249, 0)";


const LineGraphDisplay = (props) => {
  const { labels, values, loading } = props;

  if (loading) {
    return <Loading />;
  }

  // We pass a function to `data` for accessing the canvas context.
  const setupData = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradientFill = ctx.createLinearGradient(0, 20, 0, 230);
    gradientFill.addColorStop(0, BG1);
    gradientFill.addColorStop(1, BG2);

    return {
      labels: labels,
      datasets: [
        {
          lineTension: 0.3,
          backgroundColor: gradientFill,
          borderColor: BLUE,
          borderWidth: 2.5,
          pointBorderColor: BLUE,
          pointBackgroundColor: BLUE,
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: BLUE,
          pointHoverBorderColor: BLUE,
          pointHoverBorderWidth: 2,
          pointRadius: 2,
          pointHitRadius: 15,
          data: values,
        }
      ]
    }
  }

  const legend = {
    display: false
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {
          stepSize: 500
        }
      }]
    }
  };

  return (
    <div className="px-2">
      <Line
        data={setupData}
        legend={legend}
        options={options}
        height={230}
      />
    </div>
  );
}

export default LineGraphDisplay;
